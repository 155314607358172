import "./src/styles/normalize.scss";
import "./src/styles/theme.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";

export const onClientEntry = () => {
  AOS.init({
    disable: "mobile",
  });
};
